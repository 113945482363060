<template>
    <ChargingStationsTable/>
</template>

<script>
    import ChargingStationsTable from "../components/ChargingStationsTable";

    export default {
        name: "ChargingStations",
        components: {
            ChargingStationsTable
        }
    }
</script>

<style scoped>

</style>