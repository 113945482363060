import { TableChargerOverview } from '@/types/tableTypes';
import { AxiosResponse } from 'axios';
import { useAPI } from '@/composables/useAPI';
import { FormChargerType } from '@/types/RequestTypes';
import { localAuthList } from '@/types/BackendTypes';
import { UpdateType } from '@/types/enums';

export function useChargerAPI() {
    const { api } = useAPI()

    const restartChargingStationRequest = async (station: TableChargerOverview): Promise<AxiosResponse | undefined> => {
        return await api.post(`chargingStation/${station.id}/restart`)
            .catch(() => {
                return undefined
            })
    }

    const getUnassignedChargingStation = async (): Promise<AxiosResponse | undefined> => {
        return await api.get('chargingStations/unassigned')
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const getLocalAuthList = async (chargingStationId: string): Promise<localAuthList | undefined> => {
        const res = await api.get(`charger/${chargingStationId}/localAuthList/`).catch((e) => {
            console.log(e.message)
            return undefined
        })
        if (res)
            return res.data
    }

    const updateLocalAuthList = async (chargingStationId: string, whitelist: { idTag: string }[], updateType: UpdateType = UpdateType.DIFFERENTIAL): Promise<AxiosResponse | undefined> => {
        return await api.post(
            `charger/${chargingStationId}/localAuthList/`,
            {
                entries: whitelist,
                updateType: updateType
            }
        ).catch((e) => {
            console.log(e.message)
            return undefined
        })
    }

    const addChargingStationRequest = async (chargingStationId: string, companyId: string): Promise<AxiosResponse | undefined> => {
        return await api.patch(`chargingStation/${chargingStationId}/assign/${companyId}`)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const removeChargingStationRequest = async (chargerId: string) => {
        return await api.patch(`chargingStation/${chargerId}/removeAssignment`)
            .catch((e) => {
                console.log(e.message)
                return undefined
            })
    }

    const editChargingStationRequest = async (chargerId: string, charger: FormChargerType): Promise<AxiosResponse | undefined> => {
        return await api.put('charger/update/' + chargerId, charger)
            .catch((e) => {
                console.log(e.message)
                return undefined;
            })
    }

    const getChargersPaginated = async (requestParams: { companyId?: string, page: number, limit: number, searchKey?: string }): Promise<AxiosResponse | undefined> => {
        const { companyId, page, limit, searchKey } = requestParams
        return await api.get('chargingStations/' + (companyId ?? ''), {
            params: {
                page: page,
                limit: limit,
                searchKey: searchKey
            }
        })
            .catch((e) => {
                console.log(e.message)
                return undefined;
            })
    }

    const getChargersPaginatedFoal = async (requestParams: { companyId?: string, page: number, limit: number, searchKey?: string }): Promise<AxiosResponse | undefined> => {
        const { companyId, page, limit, searchKey } = requestParams
        return await api.get('charger/company/' + (companyId ?? ''), {
            params: {
                page: page,
                limit: limit,
                searchKey: searchKey
            }
        })
            .catch((e) => {
                console.log(e.message)
                return undefined;
            })
    }

    return {
        getUnassignedChargingStation,
        addChargingStationRequest,
        removeChargingStationRequest,
        restartChargingStationRequest,
        editChargingStationRequest,
        getChargersPaginated,
        getChargersPaginatedFoal,
        getLocalAuthList,
        updateLocalAuthList,
    }
}
