




















































































































import { defineComponent, onMounted, reactive, ref } from "@vue/composition-api";
import { TableChargerOverview } from "@/types/tableTypes";
import { useTable } from "@/composables/useTable";
import { useChargerAPI } from "@/api-connectors/useChargerAPI";
import { toastController } from "@/composables/useToast";
import PaginationRow from "@/components/PaginationRow.vue";

export default defineComponent({
    name: "ChargingStationsTable",
    components: { PaginationRow },
    setup() {
        const {
            // updateTable,
            pageOptions
        } = useTable()
        const { restartChargingStationRequest, getChargersPaginated } = useChargerAPI()

        const items = reactive<TableChargerOverview[]>([])
        const fields = [
            { key: 'alias', label: 'Alias' },
            { key: 'group', label: 'Gruppe' },
            { key: 'location', label: 'Standort' },
            { key: 'company', label: 'Firma' },
            { key: 'status', label: 'Status' },
            { key: 'reset', label: '' }
        ]

        const perPage = ref(10);
        const currentPage = ref(1);
        const amountRows = ref(0);
        const isBusy = ref(false);
        const filter = ref('')

        const rowClass = (item: TableChargerOverview, type: string) => {
            // console.log(item, type)
            if (!item || type !== 'row') return

            switch (item.status.statusCode) {
                case "ok":
                    return ['row-class', 'row-class-ok']
                case "error":
                    return ['row-class', 'row-class-error']
            }
        }

        const updateChargerList = async (page?: number): Promise<void> => {
            isBusy.value = true
            if (!page) page = currentPage.value;

            const responsePagination = await getChargersPaginated({
                page: page,
                limit: perPage.value,
                searchKey: filter.value
            })

            if (!responsePagination) return;

            const { list, maxCount } = responsePagination.data

            items.splice(0)
            for (const charger of list) {
                items.push(charger)
            }

            amountRows.value = maxCount

            isBusy.value = false
        }

        const restartChargingStation = (station: TableChargerOverview) => {
            const response = restartChargingStationRequest(station)

            if (!response) {
                return toastController?.toast(`${station.alias} wird resettet`, {
                    title: 'Info',
                    autoHideDelay: 3000
                })
            }
            toastController?.toast(`${station.alias} konnte nicht resettet werden`, {
                title: 'Fehler',
                variant: 'danger',
                autoHideDelay: 3000
            })
        }

        const resetFilter = () => {
            filter.value = ''
            updateChargerList()
        }

        // onMounted(() => {
        //     handler = setInterval(() => {
        //         this.$refs.chargingTable.refresh()
        //     }, 5000)
        // })
        //
        // onBeforeUnmount(() => clearInterval(handler))

        onMounted(() => updateChargerList(1))

        return {
            items,
            fields,
            isBusy,
            filter,
            pageOptions,
            currentPage,
            perPage,
            amountRows,
            rowClass,
            updateChargerList,
            restartChargingStation,
            resetFilter
        }
    }
})
